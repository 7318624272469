import React, { useState, useEffect } from "react";
import * as RB from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as utils from "../../../Utils/comman";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { setExpId } from "../../../reducers/expIdSlice";
import Swal from "sweetalert2";
import { fetchTravelExpenses } from "../../../reducers/travelExpensesSlice";
import { fetchExpensePlans } from "../../../reducers/expenseSlice";
import { isArray } from "lodash";
import { clearExp } from "../../../reducers/updateExpenseSlice";

function TravelEntitlement({ head, userPermissions, openAccordionId }) {
  const [selectedSubhead, setSelectedSubhead] = useState(null);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [formData, setFormData] = useState({
    modeOfTravel: "",
    travelClass: "",
    dateFrom: null,
    dateTo: null,
    stationFrom: "",
    stationTo: "",
    amount: "",
    kilometers: "",
    toll: "",
    total: 0,
    document: [],
  });
  console.log(openAccordionId);
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDocDragging, setIsDocDragging] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isValidImage, setisValidImage] = useState(false);
  const [isValidDoc, setisValidDoc] = useState(false);
  const [isImage, setIsImage] = useState(false);
  // const [isValidDoc, setIsValidDoc] = useState(false);
  const dispatch = useDispatch();
  const expId = useSelector((state) => state.expId);
  const [error, setError] = useState(false);
  const { expenses } = useSelector((state) => state.expenses);
  const updateExp = useSelector((state) => state.updateExp.updateExp);

  useEffect(() => {
    if (Object.keys(updateExp).length > 0) {
      console.log(updateExp);
      setFormData({
        modeOfTravel: updateExp.subHeadId,
        dateFrom: null,
        dateTo: null,
        stationFrom: updateExp.stationFrom,
        stationTo: updateExp.stationTo,
        city: updateExp.city,
        checkInDate: updateExp.dateFrom,
        checkOutDate: updateExp.dateTo,
        hotelName: updateExp.hotelName,
        amount: updateExp.amount,
        description: updateExp.description,
        document: [],
      });

      const subhead = head.subheads.find(
        (sub) => sub.sub_head_id === updateExp.subHeadId
      );
      if (subhead) {
        const allowedClasses = subhead.class.filter((cls) =>
          userPermissions.some(
            (permission) =>
              permission.head_id === head.head_id &&
              permission.sub_head_id === subhead.sub_head_id &&
              (permission.classId === cls.class_id ||
                permission.classId === "0")
          )
        );

        setFilteredClasses(allowedClasses);
      }

      // setFormData({
      //   travelClass: updateExp.classId,
      // });
    }
  }, [updateExp]);

  useEffect(() => {
    if (filteredClasses.length !== 0) {
      if (Object.keys(updateExp).length > 0) {
        setFormData({
          modeOfTravel: updateExp.subHeadId,
          dateFrom: null,
          dateTo: null,
          stationFrom: updateExp.stationFrom,
          stationTo: updateExp.stationTo,
          city: updateExp.city,
          checkInDate: updateExp.dateFrom,
          checkOutDate: updateExp.dateTo,
          hotelName: updateExp.hotelName,
          amount: updateExp.amount,
          description: updateExp.description,
          document: [],
          travelClass: updateExp.classId,
        });
      }
    }
  }, [filteredClasses]);
  const getRatePerKm = (subHeadId) => {
    const permission = userPermissions.find(
      (perm) =>
        perm.head_id === head.head_id &&
        perm.sub_head_id === subHeadId &&
        perm.value
    );
    return permission ? parseFloat(permission.value) : 0;
  };

  const handleSubheadSelect = (event) => {
    const subheadId = event.target.value;
    const subhead = head.subheads.find((sub) => sub.sub_head_id === subheadId);
    setSelectedSubhead(subhead);
    setFormData({
      ...formData,
      modeOfTravel: subheadId,
      amount: "",
      total: "",
      toll: "",
      kilometers: "",
    });

    const allowedClasses = subhead.class.filter((cls) =>
      userPermissions.some(
        (permission) =>
          permission.head_id === head.head_id &&
          permission.sub_head_id === subhead.sub_head_id &&
          (permission.classId === cls.class_id || permission.classId === "0")
      )
    );

    setFilteredClasses(allowedClasses);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: value };
      if (
        updatedFormData.modeOfTravel === "7" ||
        updatedFormData.modeOfTravel === "8"
      ) {
        const ratePerKm = getRatePerKm(updatedFormData.modeOfTravel);
        const kilometers = parseFloat(updatedFormData.kilometers) || 0;
        const toll = parseFloat(updatedFormData.toll) || 0;
        const total = kilometers * ratePerKm + toll;
        updatedFormData.total = total;
      }
      return updatedFormData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      if (formData.modeOfTravel !== "6" && formData.document.length < 1) {
        if (updateExp) {
          if (
            Array.isArray(updateExp.expense_image) &&
            updateExp.expense_image.length !== 0
          ) {
            setIsImage(false);
          } else {
            setIsImage(true);
            return;
          }
        }
        // setIsImage(true);
        // return;
      } else {
        setIsImage(false);
      }
      setIsSubmit(true);
    }
  };

  const page = 0;
  const countPerPage = 0;

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (Object.keys(updateExp).length > 0) {
        bodyFormData.append("id", updateExp.id);
      }
      bodyFormData.append("expense_type", "travel");
      bodyFormData.append("planId", openAccordionId);
      bodyFormData.append("headId", head.head_id);
      bodyFormData.append("subHeadId", formData.modeOfTravel);
      bodyFormData.append("classId", formData.travelClass);
      bodyFormData.append(
        "dateFrom",
        ""
        // moment(formData.dateFrom).format("YYYY-MM-DD")
      );
      bodyFormData.append(
        "dateTo",
        ""
        // moment(formData.dateTo).format("YYYY-MM-DD")
      );
      bodyFormData.append("stationFrom", formData.stationFrom);
      bodyFormData.append("stationTo", formData.stationTo);
      bodyFormData.append("hotelName", formData.hotelName || "");
      bodyFormData.append("tollAmount", formData.toll || "0.00");
      bodyFormData.append(
        "amount",
        formData.amount || formData.total || "0.00"
      );
      bodyFormData.append("expId", expId || "");
      bodyFormData.append("city", "");
      bodyFormData.append("description", "");
      bodyFormData.append("nature_of_expense", "");
      formData.document.forEach((doc, index) => {
        bodyFormData.append(`expense_images[]`, doc);
      });

      utils
        .callAPI("post", `/expense/save`, bodyFormData)
        .then((res) => {
          console.log(res);
          if (typeof res !== "undefined") {
            dispatch(clearExp());
            dispatch(setExpId(res.expId));
            setIsSubmit(false);
            setFormData({
              modeOfTravel: "",
              travelClass: "",
              dateFrom: null,
              dateTo: null,
              stationFrom: "",
              stationTo: "",
              amount: "",
              kilometers: "",
              toll: "",
              total: 0,
              document: [],
            });
            setValidated(false);
            dispatch(fetchTravelExpenses({ page, countPerPage }));
            dispatch(fetchExpensePlans({ planId: openAccordionId }));
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  const handleNumberInput = (event) => {
    const { value } = event.target;
    if (!/^\d*\.?\d*$/.test(value)) {
      event.preventDefault();
    }
  };

  const filteredSubheads = head.subheads.filter((subhead) =>
    userPermissions.some(
      (permission) =>
        permission.head_id === head.head_id &&
        (permission.sub_head_id === subhead.sub_head_id ||
          permission.sub_head_id === "0")
    )
  );

  const handleDragOver = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(true);
    } else {
      setIsDocDragging(true);
    }
  };

  const handleDragEnter = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(true);
    } else {
      setIsDocDragging(true);
    }
  };

  const handleDragLeave = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(false);
    } else {
      setIsDocDragging(false);
    }
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    setIsDraggingOver(false);
    setIsDocDragging(false);

    const files = Array.from(e.dataTransfer.files);
    const validExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ];
    const isValidFileType = files.every((file) =>
      validExtensions.includes(file.type)
    );

    if (!isValidFileType) {
      setisValidDoc(true);
      return;
    }

    setisValidDoc(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      document: [...prevFormData.document, ...files],
    }));
  };

  const handleDocumentChange = (e) => {
    const files = Array.from(e.target.files);
    const validExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ];
    const isValidFileType = files.every((file) =>
      validExtensions.includes(file.type)
    );

    if (!isValidFileType) {
      setisValidDoc(true);
      return;
    }

    setisValidDoc(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      document: [...prevFormData.document, ...files],
    }));
  };

  const handleDeleteDocument = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      document: prevFormData.document.filter((_, i) => i !== index),
    }));
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, dateFrom: date });

    const selectedDate = moment(date);
    const travelEntitlementRanges = expenses?.result
      .flatMap((expense) => expense.expList)
      .filter((expenseItem) => expenseItem.headId === "2")
      .map((expenseItem) => ({
        dateFrom: expenseItem.dateFrom,
      }));

    const isDateInRange = travelEntitlementRanges.some((range) =>
      selectedDate.isSame(range.dateFrom, "day")
    );

    if (isDateInRange) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "This date is already used for Local Conveyance.Cannot add travel entitlement expense on this date.",
      });
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <RB.Card>
      <RB.Card.Body>
        <h5>{head.head_name}</h5>
        <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
          <RB.Row>
            <RB.Col md={6} className="mb-3">
              <RB.FloatingLabel
                controlId="formModeOfTravel"
                label="Mode Of Travel"
                className="floating-label-fancy"
              >
                <RB.Form.Select
                  value={formData.modeOfTravel}
                  onChange={handleSubheadSelect}
                  required
                >
                  <option value="">Select Mode of Travel</option>
                  {filteredSubheads.map((subhead) => (
                    <option
                      key={subhead.sub_head_id}
                      value={subhead.sub_head_id}
                    >
                      {subhead.sub_head_name}
                    </option>
                  ))}
                </RB.Form.Select>
              </RB.FloatingLabel>
            </RB.Col>

            {filteredClasses?.length > 0 && (
              <RB.Col md={6} className="mb-3">
                <RB.FloatingLabel
                  controlId="formClass"
                  label="Class"
                  className="floating-label-fancy"
                >
                  <RB.Form.Select
                    value={formData.travelClass}
                    onChange={(e) =>
                      setFormData({ ...formData, travelClass: e.target.value })
                    }
                    required
                  >
                    <option value="">Select Class</option>
                    {filteredClasses.map((cls) => (
                      <option key={cls.class_id} value={cls.class_id}>
                        {cls.sub_head_name}
                      </option>
                    ))}
                  </RB.Form.Select>
                </RB.FloatingLabel>
              </RB.Col>
            )}
            {/* <RB.Col md={6} className="mb-3 mt-3">
              <RB.FloatingLabel
                label="Date From"
                className={`floating-label-fancy ${
                  formData.dateFrom ? "dateSelected" : "dateempty"
                }`}
              >
                <DatePicker
                  selected={formData.dateFrom}
                  // onChange={(date) =>
                  //   setFormData({ ...formData, dateFrom: date })
                  // }
                  onChange={handleDateChange}
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  minDate={new Date()}
                  required
                />
              </RB.FloatingLabel>
            </RB.Col>
            <RB.Col md={6} className="mb-3 mt-3">
              <RB.FloatingLabel
                label="Date To"
                className={`floating-label-fancy ${
                  formData.dateTo ? "dateSelected" : "dateempty"
                }`}
              >
                <DatePicker
                  selected={formData.dateTo}
                  onChange={(date) =>
                    setFormData({ ...formData, dateTo: date })
                  }
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  required
                  minDate={formData.dateFrom}
                />
              </RB.FloatingLabel>
            </RB.Col> */}
            <RB.Col md={6} className="mb-3">
              <RB.FloatingLabel
                controlId="formStationFrom"
                label="Station From"
                className="floating-label-fancy"
              >
                <RB.Form.Control
                  type="text"
                  placeholder="Station From"
                  name="stationFrom"
                  value={formData.stationFrom}
                  onChange={handleInputChange}
                  required
                />
              </RB.FloatingLabel>
            </RB.Col>
            <RB.Col md={6} className="mb-3">
              <RB.FloatingLabel
                controlId="formStationTo"
                label="Station To"
                className="floating-label-fancy"
              >
                <RB.Form.Control
                  type="text"
                  placeholder="Station To"
                  name="stationTo"
                  value={formData.stationTo}
                  onChange={handleInputChange}
                  required
                />
              </RB.FloatingLabel>
            </RB.Col>

            {formData.modeOfTravel === "7" || formData.modeOfTravel === "8" ? (
              <>
                <RB.Col md={6} className="mb-3">
                  <RB.FloatingLabel
                    controlId="formKilometers"
                    label="Kilometers"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Kilometers"
                      name="kilometers"
                      value={formData.kilometers}
                      required
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(
                          /[^0-9.]/g,
                          ""
                        ))
                      }
                      onChange={handleInputChange}
                    />
                  </RB.FloatingLabel>
                </RB.Col>
                {formData.modeOfTravel !== "8" && (
                  <RB.Col md={6} className="mb-3">
                    <RB.FloatingLabel
                      controlId="formToll"
                      label="Toll"
                      className="floating-label-fancy"
                    >
                      <RB.Form.Control
                        type="text"
                        placeholder="Toll"
                        name="toll"
                        value={formData.toll}
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(
                            /[^0-9.]/g,
                            ""
                          ))
                        }
                        onChange={handleInputChange}
                      />
                    </RB.FloatingLabel>
                  </RB.Col>
                )}
              </>
            ) : (
              <RB.Col md={6} className="mb-3">
                <RB.FloatingLabel
                  controlId="formAmount"
                  label="Amount"
                  className="floating-label-fancy"
                >
                  <RB.Form.Control
                    type="text"
                    placeholder="Amount"
                    name="amount"
                    value={formData.amount}
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^0-9.]/g, ""))
                    }
                    onChange={handleInputChange}
                    required
                  />
                </RB.FloatingLabel>
              </RB.Col>
            )}
          </RB.Row>
          <RB.Row className="mt-2">
            <RB.Col className="text-start">
              {formData.modeOfTravel === "7" ||
              formData.modeOfTravel === "8" ? (
                <h6 className="mt-2">
                  Total: ₹ {formData.total ? formData.total : 0}
                </h6>
              ) : (
                <h6 className="mt-2">
                  Total: ₹ {formData.amount ? formData.amount : 0}
                </h6>
              )}
            </RB.Col>
          </RB.Row>
          <RB.Row>
            <RB.Col md={6}>
              <RB.Card className="mb-0">
                <RB.CardBody className="p-2">
                  <h6 className="mb-2">Upload Bills</h6>
                  <hr />
                  <div
                    className={`border p-4 text-center d-flex flex-column align-items-center ${
                      isDocDragging ? "bg-info" : ""
                    }`}
                    onDragOver={(e) => handleDragOver(e, "doc")}
                    onDragEnter={(e) => handleDragEnter(e, "doc")}
                    onDragLeave={(e) => handleDragLeave(e, "doc")}
                    onDrop={(e) => handleDrop(e, "doc")}
                  >
                    <div className="avatar avatar-circle avatar-lg bg-light text-dark">
                      <i className="material-icons-outlined">cloud_upload</i>
                    </div>
                    <p className="mb-0">Drag & Drop your bills here</p>
                    <small className="mb-2">or</small>
                    <div>
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png,.pdf"
                        multiple
                        style={{ display: "none" }}
                        onChange={handleDocumentChange}
                        id="docInput"
                      />
                      <RB.Button
                        variant="primary"
                        className="me-2"
                        onClick={() =>
                          document.getElementById("docInput").click()
                        }
                      >
                        <i className="las la-file-upload me-1"></i>Browse Bills
                      </RB.Button>
                    </div>
                  </div>
                  <span className={`${isValidDoc ? "text-danger" : ""}`}>
                    <small>
                      *Note: Only jpg,jpeg,png and pdf Files are allowed
                    </small>
                  </span>
                  <RB.Row className="mt-3 g-3"></RB.Row>
                </RB.CardBody>
              </RB.Card>
              {isImage && (
                <div className="text-danger">
                  Please upload at least one bill document.
                </div>
              )}
            </RB.Col>
            <RB.Col md={6}>
              <RB.Row>
                {formData?.document?.map((doc, index) => (
                  <RB.Col md={4} key={index}>
                    <div className="position-relative me-2 mb-4 rounded border p-3 text-center">
                      <div className="position-relative">
                        {doc.type === "application/pdf" ? (
                          <a
                            href={URL.createObjectURL(doc)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-decoration-none text-dark "
                          >
                            <div className="product-image-upload-trael">
                              <i className="material-icons-outlined md-64 mt-4">
                                picture_as_pdf
                              </i>
                            </div>
                          </a>
                        ) : (
                          <div className="product-image-upload-trael">
                            <img
                              src={URL.createObjectURL(doc)}
                              alt={`Image ${index}`}
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </RB.Col>
                ))}
                {Array.isArray(updateExp.expense_image) &&
                  updateExp.expense_image.length !== 0 && (
                    <>
                      {updateExp?.expense_image?.map((expImage, index) => (
                        <RB.Col md={4} key={`update-exp-image-${index}`}>
                          <div className="position-relative me-2 mb-4 rounded border p-3 text-center">
                            <div className="position-relative">
                              {expImage.expense_image.endsWith(".pdf") ? (
                                <a
                                  href={expImage.expensePath}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-decoration-none text-dark"
                                >
                                  <div className="product-image-upload-trael">
                                    <i className="material-icons-outlined md-64 mt-4">
                                      picture_as_pdf
                                    </i>
                                  </div>
                                </a>
                              ) : (
                                <div className="product-image-upload-trael">
                                  <img
                                    src={expImage.expensePath}
                                    alt={`Image ${index}`}
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </RB.Col>
                      ))}
                    </>
                  )}
              </RB.Row>
            </RB.Col>
          </RB.Row>
          <RB.Row className="mt-2">
            <RB.Col className="text-end">
              <RB.Button variant="success" type="submit" disabled={error}>
                Add To List
              </RB.Button>
            </RB.Col>
          </RB.Row>
        </RB.Form>
      </RB.Card.Body>
    </RB.Card>
  );
}

export default TravelEntitlement;

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import * as utils from "../Utils/comman";
import { useSelector } from "react-redux";
import NoPermission from "./NoPermission";

export const ProtectedRoute = () => {
  // const permissions = useSelector(
  //   (state) => state?.primaryMenu?.primaryMenu?.permissions
  // );
  let is_auth = utils.isAuthenticated();
  // if (is_auth) {
  //   if (!permissions) {
  //     return <NoPermission />;
  //   }
  // }
  return is_auth ? <Outlet /> : <Navigate to="/" />;
};

import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import ApprovedPlanList from "./Travel/ApprovedPlanList";
import TravelPlanSceduleList from "./Travel/TravelPlanSceduleList";
import { setExpId } from "../../reducers/expIdSlice";
import { useDispatch } from "react-redux";
import { fetchTravelHeadList } from "../../reducers/travelHeadSlice";
import { fetchTravelPermissionList } from "../../reducers/travelPermissionSlice";
import * as utils from "../../Utils/comman";
import LocalExpenseList from "./Local/LocalExpenseList";

function ExpensesTypes() {
  const [activeTab, setActiveTab] = useState("travel");
  const [travelPlan, setTravelPlan] = useState({});
  const [showScedule, setShowScedule] = useState(false);
  const [comp, setCompo] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    getExpId();
    dispatch(fetchTravelHeadList());
    dispatch(fetchTravelPermissionList());
  }, []);

  const getExpId = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const url = `/expense/get-expenseid?date=${currentDate}`;

    utils
      .callAPI("get", url)
      .then((res) => {
        if (typeof res !== "undefined") {
          dispatch(setExpId(res.expId));
        } else {
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <RB.Card className="card shadow">
        {/* <RB.Card.Header className="border-0">
          <h4></h4>
          <div className="card-header-action"></div>
        </RB.Card.Header> */}
        <RB.Card.Body className="kkk p-2">
          <RB.Tab.Container
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
          >
            <RB.Nav variant="tabs">
              <RB.Nav.Item>
                <RB.Nav.Link eventKey="travel">Travel</RB.Nav.Link>
              </RB.Nav.Item>
              <RB.Nav.Item>
                <RB.Nav.Link eventKey="local">Local</RB.Nav.Link>
              </RB.Nav.Item>
            </RB.Nav>
            <RB.Tab.Content className="mt-1">
              <RB.Tab.Pane eventKey="travel">
                <div>
                  <div>
                    {showScedule ? (
                      <>
                        <TravelPlanSceduleList
                          travelPlan={travelPlan}
                          setShowScedule={setShowScedule}
                          activeTab={activeTab}
                          comp={comp}
                        />
                      </>
                    ) : (
                      <>
                        <ApprovedPlanList
                          setTravelPlan={setTravelPlan}
                          setShowScedule={setShowScedule}
                          setCompo={setCompo}
                        />
                      </>
                    )}
                  </div>
                </div>
              </RB.Tab.Pane>
              <RB.Tab.Pane eventKey="local">
                  <div>
                    {activeTab == "local" && <LocalExpenseList activeTab={activeTab}/>}
                  </div>
              </RB.Tab.Pane>
            </RB.Tab.Content>
          </RB.Tab.Container>
        </RB.Card.Body>
      </RB.Card>
    </>
  );
}

export default ExpensesTypes;

const NoPermission = () => {
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    window.location.href = "/"; 
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 text-center flex-column">
      <h1>You don’t have permission, please contact admin.</h1>
      <button className="btn btn-primary mt-4" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default NoPermission;
